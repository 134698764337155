import { useState } from "react";
import SideCart from "@/components/SideCart";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useCartStore } from "@/store/useCartStore";
import { Heart, Menu, Search, ShoppingCart } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/logo.png";
import NavigationLinks from "./NavigationLinks";
import MobileMenu from "./MobileMenu";

function Header() {
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const { cart } = useCartStore();
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isSideSheetOpen, setIsSideSheetOpen] = useState(false);
  const handleSheetClose = () => {
    setIsSheetOpen(false);
  };

  return (
    <div>
      <header className="flex shadow-md py-4 px-4 sm:px-10 bg-white font-sans min-h-[70px] tracking-wide relative z-50">
        <div className="flex flex-wrap items-center justify-between gap-4 w-full">
          <Link to="/">
            <img src={logo} alt="logo" className="w-16" />
          </Link>

          <NavigationLinks isActive={isActive} />

          <div className="flex items-center max-lg:ml-auto space-x-5">
            <Tooltip>
              <TooltipTrigger>
                <Search />
              </TooltipTrigger>
              <TooltipContent>
                <p>search</p>
              </TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger>
                <Link
                  to="/wishlist"
                  className={`hover:text-[#27812c] ${
                    isActive("/wishlist") ? " text-[#27812c]" : ""
                  } font-bold block text-base`}
                >
                  <Heart />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                <p>Add to WishList</p>
              </TooltipContent>
            </Tooltip>

            <Sheet open={isSideSheetOpen} onOpenChange={setIsSideSheetOpen}>
              <SheetTrigger>
                <Button variant={"ghost"} className="px-2">
                  <span className="relative">
                    <ShoppingCart />
                    <span className="absolute right-auto ml-1 -top-1 rounded-full bg-green-500 px-1 py-0 text-xs text-white">
                      {cart.length}
                    </span>
                  </span>
                </Button>
              </SheetTrigger>

              <SheetContent>
                <SideCart setIsSideSheetOpen={setIsSideSheetOpen} />
              </SheetContent>
            </Sheet>

            <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
              <SheetTrigger>
                <button id="toggleOpen" className="lg:hidden !ml-7">
                  <Menu size={25} />
                </button>
              </SheetTrigger>
              <SheetContent side={"left"}>
                <MobileMenu
                  handleSheetClose={handleSheetClose}
                  isActive={isActive}
                />
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
