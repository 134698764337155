import React from "react";
import { Link } from "react-router-dom";

interface NavigationLinksProps {
  isActive: (path: string) => boolean;
}

const NavigationLinks: React.FC<NavigationLinksProps> = ({ isActive }) => {
  return (
    <div
      id="collapseMenu"
      className={`max-lg:hidden lg:!block max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50 
                block`}
    >
      <ul className="lg:flex gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50">
        <li className="max-lg:border-b max-lg:py-3 px-3">
          <Link
            to="/"
            className={`hover:text-[#27812c] ${
              isActive("/") ? " text-[#27812c]" : ""
            } font-bold block text-base`}
          >
            Home
          </Link>
        </li>
        <li className="max-lg:border-b max-lg:py-3 px-3">
          <Link
            to="/products"
            className={`hover:text-[#27812c] ${
              isActive("/products") ? " text-[#27812c]" : ""
            } font-bold block text-base`}
          >
            Products
          </Link>
        </li>
        <li className="max-lg:border-b max-lg:py-3 px-3">
          <Link
            to="/about"
            className={`hover:text-[#27812c] ${
              isActive("/about") ? " text-[#27812c]" : ""
            } font-bold block text-base`}
          >
            About Us
          </Link>
        </li>
        <li className="max-lg:border-b max-lg:py-3 px-3">
          <a className="hover:text-[#27812c] text-black font-bold block text-base">
            Order Tracking
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NavigationLinks;
