import axios from "axios";

const BASE_URL = "https://shokify.store/api";

export const createOrder = async (orderData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/order`, orderData);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response?.data?.message || "Error creating order");
  }
};

// Add other product-related API calls as needed
