import React, { useMemo } from "react";
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "./ui/button";
import { Link, useNavigate } from "react-router-dom";
import { CartItem, useCartStore } from "@/store/useCartStore";
import { ShoppingBag, Trash } from "lucide-react";

interface SideCartProps {
  setIsSideSheetOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideCart: React.FC<SideCartProps> = ({ setIsSideSheetOpen }) => {
  const { cart, removeFromCart } = useCartStore();
  const navigate = useNavigate();

  const subtotal = useMemo(
    () =>
      cart.reduce((acc, product) => acc + product.price * product.quantity, 0),
    [cart]
  );

  const handleRemoveFromCart = (id: string) => {
    removeFromCart(id);
  };

  const handleCheckout = () => {
    setIsSideSheetOpen(false);
    navigate("/checkout");
  };

  const handleViewCart = () => {
    setIsSideSheetOpen(false);
    navigate("/cart");
  };

  const handleContinueShopping = () => {
    setIsSideSheetOpen(false);
  };

  return (
    <SheetHeader>
      <SheetTitle className="text-lg font-medium text-gray-900">
        Shopping cart
      </SheetTitle>
      <SheetDescription>
        {cart.length === 0 ? (
          <div className="h-screen flex justify-center items-center">
            <div className="items-center justify-center flex flex-col">
              <ShoppingBag size={100} className="text-green-500" />
              <div className="my-10">Your Cart is Empty</div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex-1 overflow-y-auto py-6 sm:px-6 max-h-[calc(100vh-220px)]">
              <div className="mt-8">
                <div className="flow-root">
                  <ul role="list" className="-my-6 divide-y divide-gray-200">
                    {cart.map((product: CartItem) => (
                      <li key={product._id} className="flex py-6">
                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                          <img
                            src={`https://shokify.s3.eu-north-1.amazonaws.com/${product.images[0].image}`}
                            alt={product.title}
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3 className="line-clamp-3 hover:underline">
                                <Link to={`/product/${product._id}`}>
                                  {product.title}
                                </Link>
                              </h3>
                              <p className="ml-4">{product.price}</p>
                            </div>
                          </div>
                          <div className="flex flex-1 items-end justify-between text-sm">
                            <p className="text-gray-500">
                              Qty {product.quantity}
                            </p>
                            <div className="flex">
                              <button
                                type="button"
                                className="font-medium text-green-600 hover:text-red-500"
                                onClick={() =>
                                  handleRemoveFromCart(product._id)
                                }
                              >
                                <Trash />
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
              <div className="flex justify-between text-base font-medium text-gray-900">
                <p>Subtotal</p>
                <p>Rs. {subtotal.toFixed(2)}</p>
              </div>
              <p className="mt-0.5 text-sm text-gray-500">
                Shipping and taxes calculated at checkout.
              </p>
              <div className="mt-6 flex justify-center items-center gap-4">
                <Button
                  className="flex-1"
                  onClick={handleCheckout}
                  disabled={cart.length === 0}
                >
                  Checkout
                </Button>
                <Button className="flex-1" onClick={handleViewCart}>
                  Cart
                </Button>
              </div>
              <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                <p>
                  or{" "}
                  <button
                    type="button"
                    onClick={handleContinueShopping}
                    className="font-medium text-green-600 hover:text-indigo-500"
                  >
                    Continue Shopping
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </p>
              </div>
            </div>
          </>
        )}
      </SheetDescription>
    </SheetHeader>
  );
};

export default SideCart;
