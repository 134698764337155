import { createOrder } from "@/api";
import { redirect } from "react-router-dom";

export async function CheckoutAction({ request, params }) {
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  if (data.orderItems) {
    data.orderItems = JSON.parse(data.orderItems);
  }
  console.log(data);
  await createOrder(data);

  return redirect("/");
}
