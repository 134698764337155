import { create } from "zustand";
import { persist } from "zustand/middleware";

// Define the types for your cart item and cart state
interface Image {
  image: string;
  hash: string;
  _id: string;
}

export interface CartItem {
  _id: string;
  title: string;
  description: string;
  category: string;
  subCategory: string;
  price: number;
  comparePrice: number;
  originalPrice: number;
  margin: number;
  profit: number;
  images: Image[];
  quantity: number;
  __v: number;
}

interface CartState {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (_id: string) => void;
  clearCart: () => void;
  increaseQuantity: (_id: string) => void;
  decreaseQuantity: (_id: string) => void;
}

// Create the store with persistence
export const useCartStore = create(
  persist<CartState>(
    (set) => ({
      cart: [],
      addToCart: (item) =>
        set((state) => {
          const existingItem = state.cart.find(
            (cartItem) => cartItem._id === item._id
          );
          if (existingItem) {
            return {
              cart: state.cart.map((cartItem) =>
                cartItem._id === item._id
                  ? { ...cartItem, quantity: cartItem.quantity + item.quantity }
                  : cartItem
              ),
            };
          }
          return {
            cart: [...state.cart, { ...item, quantity: item.quantity || 1 }],
          };
        }),
      removeFromCart: (_id) =>
        set((state) => ({
          cart: state.cart.filter((cartItem) => cartItem._id !== _id),
        })),
      clearCart: () => set({ cart: [] }),
      increaseQuantity: (_id) =>
        set((state) => ({
          cart: state.cart.map((cartItem) =>
            cartItem._id === _id
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          ),
        })),
      decreaseQuantity: (_id) =>
        set((state) => ({
          cart: state.cart.map((cartItem) =>
            cartItem._id === _id && cartItem.quantity > 1
              ? { ...cartItem, quantity: cartItem.quantity - 1 }
              : cartItem
          ),
        })),
    }),
    {
      name: "cart-storage",
      getStorage: () => localStorage,
    }
  )
);
