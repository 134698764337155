import axios from "axios";

const BASE_URL = "https://shokify.store/api";

export const getProduct = async (productId: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/product/${productId}`);
    return response.data.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error fetching product");
  }
};

export const createProduct = async (productData: any) => {
  try {
    const response = await axios.post(`${BASE_URL}/products`, productData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error creating product");
  }
};

// Add other product-related API calls as needed
