import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import {
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

interface MobileMenuProps {
  handleSheetClose: () => void;
  isActive: (path: string) => boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  handleSheetClose,
  isActive,
}) => {
  return (
    <SheetHeader>
      <SheetTitle>
        <a href="">
          <img src={logo} alt="logo" className="w-28 h-20" />
        </a>
      </SheetTitle>
      <SheetDescription>
        <ul className=" gap-10   ">
          <li className="max-lg:border-b max-lg:py-3 px-3">
            <Link
              to="/"
              className={`hover:text-[#27812c] ${
                isActive("/") ? " text-[#27812c]" : ""
              } font-bold block text-base`}
              onClick={handleSheetClose}
            >
              Home
            </Link>
          </li>
          <li className="max-lg:border-b max-lg:py-3 px-3">
            <Link
              to="/products"
              className={`hover:text-[#27812c] ${
                isActive("/products/all-products") ? " text-[#27812c]" : ""
              } font-bold block text-base`}
              onClick={handleSheetClose}
            >
              Products
            </Link>
          </li>
          <li className="max-lg:border-b max-lg:py-3 px-3">
            <Link
              to="/about"
              className={`hover:text-[#27812c] ${
                isActive("/about") ? " text-[#27812c]" : ""
              } font-bold block text-base`}
              onClick={handleSheetClose}
            >
              About Us
            </Link>
          </li>
          <li className="max-lg:border-b max-lg:py-3 px-3">
            <a
              href="#"
              className="hover:text-[#27812c] text-black font-bold block text-base"
            >
              Order Tracking
            </a>
          </li>
        </ul>
      </SheetDescription>
    </SheetHeader>
  );
};

export default MobileMenu;
