import React from "react";
import Logo from "../assets/logo.png";
import { Loader as Load } from "lucide-react";
function Loader() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col justify-center items-center">
        <img className="h-20 w-20" src={Logo} alt="Shokify" />
        <Load className="animate-spin text-green-500" />
      </div>
    </div>
  );
}

export default Loader;
