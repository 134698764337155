import React from "react";

const FloatingWhatsAppButton: React.FC = () => {
  const handleClick = () => {
    window.open("https://wa.me/+923064274001", "_blank"); // Replace '1234567890' with your WhatsApp number
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-4 right-4 bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600 transition-colors"
      style={{ zIndex: 1000 }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-phone"
      >
        <path d="M22 16.92V21a2 2 0 0 1-2.18 2 19.87 19.87 0 0 1-8.63-2.87A19.42 19.42 0 0 1 3.87 14.2 19.87 19.87 0 0 1 1 5.82 2 2 0 0 1 3 3h4.09a2 2 0 0 1 2 1.72c.12.73.29 1.45.5 2.15a2 2 0 0 1-.45 1.94L7.91 10.91a16 16 0 0 0 6.09 6.09l1.09-1.09a2 2 0 0 1 1.94-.45c.7.21 1.42.38 2.15.5a2 2 0 0 1 1.72 2z"></path>
      </svg>
    </button>
  );
};

export default FloatingWhatsAppButton;
