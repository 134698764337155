import React, { Suspense, lazy } from "react";
import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import UserLayout from "./layouts/user";
import AdminLayout from "./layouts/admin";
import { TooltipProvider } from "./components/ui/tooltip";
import FloatingWhatsAppButton from "./components/WhatsApp";
import { Toaster } from "./components/ui/sonner";
import { HelmetProvider } from "react-helmet-async";
import Loader from "./components/Loader";
import { CheckoutAction } from "./views/user/Checkout/CheckoutAction";
import { productLoader } from "./views/user/Product/ProductLoader";
import { Error as ProductError } from "./views/user/Product/Error";
import { checkoutProductLoader } from "./views/user/Checkout/CheckOutLoader";
import { categoryLoader } from "./views/admin/CreateProduct/CreateProductLoader";

const Home = lazy(() => import("./views/user/Home"));
const About = lazy(() => import("./views/user/About"));
const Products = lazy(() => import("./views/user/Products"));
const WishList = lazy(() => import("./views/user/WishList"));
const Cart = lazy(() => import("./views/user/Cart"));
const Product = lazy(() => import("./views/user/Product"));
const Faqs = lazy(() => import("./views/user/Faqs"));
const PrivacyPolicy = lazy(() => import("./views/user/Privacy"));
const TermsOfServices = lazy(() => import("./views/user/Terms"));
const ShippingPolicy = lazy(() => import("./views/user/Shipping"));
const ContactUs = lazy(() => import("./views/user/Contact"));
const RefundsPolicy = lazy(() => import("./views/user/Home/RefundsPolicy"));
const PolicyShipping = lazy(() => import("./views/user/Home/PolicyShipping"));
const AllProducts = lazy(() => import("./views/admin/Products"));
const AddProduct = lazy(() => import("./views/admin/CreateProduct"));
const CheckOut = lazy(() => import("./views/user/Checkout"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<UserLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="/products/:category?" element={<Products />} />
        <Route
          path="/product/:id"
          element={<Product />}
          loader={productLoader}
          errorElement={<ProductError />}
        />
        <Route path="cart" element={<Cart />} />
        <Route path="wishlist" element={<WishList />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refunds-policy" element={<RefundsPolicy />} />
        <Route path="/shipping-policy" element={<ShippingPolicy />} />
        <Route path="/terms-of-services" element={<TermsOfServices />} />
        <Route path="/Policy-shipping" element={<PolicyShipping />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="checkout/:id?/:quantity?"
          element={<CheckOut />}
          action={CheckoutAction}
          loader={checkoutProductLoader}
        />
      </Route>
      <Route element={<AdminLayout />}>
        <Route path="dashboard">
          <Route index element={<>Admin</>} />
          <Route path="products">
            <Route index element={<AllProducts />} />
            <Route
              path="new-product"
              element={<AddProduct />}
              loader={categoryLoader}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const App: React.FC = () => {
  return (
    <>
      <TooltipProvider>
        <FloatingWhatsAppButton />
        <Toaster position="top-right" richColors />
        <Suspense fallback={<Loader />}>
          <HelmetProvider>
            <RouterProvider router={router} />
          </HelmetProvider>
        </Suspense>
      </TooltipProvider>
    </>
  );
};

export default App;
