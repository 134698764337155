import { LinkedinIcon } from "lucide-react";
import React from "react";
import { FaFacebook, FaTwitch, FaTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div>
      <footer className="bg-gray-900 p-10 font-[sans-serif] tracking-wide">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="lg:flex lg:items-center">
            <Link to="/">
              <p className="font-bold text-[50px] text-white w-52">Shokify</p>
            </Link>
          </div>

          <div className="lg:flex lg:items-center">
            <ul className="flex space-x-6">
              <li>
                <FaFacebook className="text-white " size={25} />
              </li>
              <li>
                <LinkedinIcon className="text-white" size={25} />
              </li>
              <li>
                <FaTwitter className="text-white" size={25} />
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">
              Contact Us
            </h4>
            <ul className="space-y-4">
              <li>
                <Link
                  to="/faqs"
                  className="text-gray-300 hover:text-white text-sm"
                >
                  Faqs
                </Link>
              </li>
              <li>
                <Link
                  to="/refunds-policy"
                  className="text-gray-300 hover:text-white text-sm"
                >
                  Refunds Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/Policy-shipping"
                  className="text-gray-300 hover:text-white text-sm"
                >
                  Shipping Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-of-services"
                  className="text-gray-300 hover:text-white text-sm"
                >
                  Terms Of Services
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-gray-300 hover:text-white text-sm"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">
              Information
            </h4>
            <ul className="space-y-4">
              <li>
                <a href="#" className="text-gray-300 hover:text-white text-sm">
                  About Us
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:text-white text-sm">
                  Terms &amp; Conditions
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:text-white text-sm">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:text-white text-sm">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <p className="text-gray-300 text-sm mt-10">
          © 2023
          <a
            href="https://readymadeui.com/"
            target="_blank"
            className="hover:underline mx-1"
          >
            ReadymadeUI
          </a>
          All Rights Reserved.
        </p>
      </footer>
    </div>
  );
};

export default Footer;
