import axios from "axios";

const BASE_URL = "https://shokify.store/api";

export const getCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/category?type=category`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response?.data?.message || "Error creating order");
  }
};

// Add other product-related API calls as needed
