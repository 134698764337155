import { getProduct } from "@/api";
import { defer } from "react-router-dom";

export async function checkoutProductLoader({ params }) {
  if (!params.id) {
    return null;
  }
  const product = getProduct(params.id);
  return defer({ product });
}
